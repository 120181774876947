<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="icon"></BaseBreadcrumb>

    <v-row>
      <v-col cols="12" md="6" class="py-0 mt-6">
        <v-card
          class="actionable-card"
          @click="onClickRemote"
          elevation="3"
          :loading="isLoading"
          :disabled="isDisabled"
        >
          <v-card-text class="pa-5">
            <div class="d-flex align-center justify-center">
              <v-btn :class="'primary'" class="elevation-0" fab dark>
                <v-icon>mdi-email</v-icon>
              </v-btn>
              <div class="ml-3 mr-1">
                <h3 class="">
                  Enviar a firmar
                </h3>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="py-0 mt-6">
        <v-card
          class="actionable-card"
          @click="onClickFace"
          elevation="3"
          :loading="isLoading"
          :disabled="isDisabled"
        >
          <v-card-text class="pa-5">
            <div class="d-flex align-center justify-center">
              <v-btn :class="'primary'" class="elevation-0" fab dark>
                <v-icon>mdi-signature</v-icon>
              </v-btn>
              <div class="ml-3 mr-1">
                <h3 class="">
                  Firma presencial
                </h3>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center mt-5">
      <v-checkbox
        v-model="keepLogged"
        @change="onKeepLogged"
        label="Mantener la sesión abierta"
      >
      </v-checkbox>
    </v-row>
  </v-container>
</template>

<script>
import { DataURLtoFile } from "../../utils/utils";

export default {
  name: "Printer",

  mounted() {
    this.keepLogged = window.StatusMaintainSession;

    if (window.hasErrorSign) {
      this.$toasted.global.toast_error({
        message: "No se ha podido firmar el documento"
      });
    } else if (window.StatusWebappFO === 40) {
      this.finallyProcess = true;

      this.$toasted.global.toast_success({
        message: "El documento se ha enviado correctamente"
      });
    } else {
      if (!this.username) this.getUserProfile();
      if (!this.credits) this.getConsumedCredits();
      if (!this.printerConfiguration) this.getPrintSettings();

      if (window.pdfReceived)
        this.pdfReceived = DataURLtoFile(
          "data:application/pdf;base64," + window.pdfReceived,
          window.pdfName ? window.pdfName : "Documento de impresora"
        );
      else {
        const refreshId = setInterval(() => {
          if (window.pdfReceived) {
            try {
              this.pdfReceived = DataURLtoFile(
                "data:application/pdf;base64," + window.pdfReceived,
                window.pdfName ? window.pdfName : "Documento de impresora"
              );
              clearInterval(refreshId);
            } catch (e) {
              console.error(e);
              clearInterval(refreshId);
            }
          }
          this.keepLogged = window.StatusMaintainSession;
        }, 1000);
      }
    }
  },
  watch: {
    isLoading: {
      handler(val, oldVal) {
        if (oldVal && !val && !window.isRedirect) {
          if (this.printerConfiguration) {
            if (this.printerConfiguration.requestType === 1) {
              this.onClickRemote();
            } else if (this.printerConfiguration.requestType === 2) {
              this.onClickFace();
            }
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    isLoading() {
      return (
        !this.finallyProcess &&
        (!this.pdfReceived ||
          !this.username ||
          !this.credits ||
          !this.printerConfiguration)
      );
    },
    isDisabled() {
      return (
        this.finallyProcess ||
        !this.pdfReceived ||
        !this.username ||
        !this.credits ||
        !this.printerConfiguration
      );
    },
    printerConfiguration() {
      return this.$store.state.printerConfiguration
        ? this.$store.state.printerConfiguration
        : null;
    },
    credits() {
      return this.$store.state.user.limitCredits &&
        this.$store.state.user.limitCredits !== "-"
        ? this.$store.state.user.limitCredits -
            this.$store.state.user.consumedCredits
        : 0;
    },
    username() {
      return this.$store.state.user.userProfile != undefined
        ? this.$store.state.user.userProfile.fullName
        : "";
    }
  },
  methods: {
    getPrintSettings() {
      this.$store.dispatch("getPrinterConfiguration").catch(err => {
        console.log(err);
        this.$toasted.global.toast_error({
          message: "No se ha podido obtener la configuración"
        });
      });
    },
    onKeepLogged() {
      window.StatusMaintainSession = this.keepLogged;
    },
    getUserProfile() {
      this.$store.dispatch("user/getUserProfile").catch(err => {
        console.log(err);
      });
    },
    getConsumedCredits() {
      this.$store.dispatch("user/getUserCredits").catch(err => {
        console.log(err);
      });
    },
    onClickRemote() {
      window.isRedirect = true;
      this.$router.push({
        name: "NewDocumentSet",
        params: {
          files: [this.pdfReceived]
        }
      });
    },
    onClickFace() {
      window.isRedirect = true;
      this.$router.push({
        name: "NewFaceToFaceSignature",
        params: {
          file: this.pdfReceived
        }
      });
    }
  },
  data: () => ({
    keepLogged: false,
    finallyProcess: false,
    page: {
      title: "Impresora firmar.online"
    },
    icon: "mdi-cloud-print-outline",
    pdfReceived: null
  })
};
</script>
